import './App.css'
import {useEffect, useMemo, useState} from 'react'
import useInput from './hooks/input.hook'
import usePayApi from './api/pay.api'


function App() {
  const {get, pay} = usePayApi()

  const [alert, setAlert] = useState(null)
  const [invoice, setIncoice] = useState(null)
  const [hash, setHash] = useState()

  const [timer, setTimer] = useState(null)

  const number = useInput()
  const month = useInput()
  const year = useInput()
  const cvv = useInput()

  const pushAlert = (alert) => {
    setAlert(alert)

    if(timer) { clearTimeout(timer) }
    const newTimer = setTimeout(() => setAlert(null), 500)
    setTimer(newTimer)
  }
  const setMess = (mess) => pushAlert({ error: false, mess })
  const setError = (mess) => pushAlert({ error: true, mess })


  useEffect(() => {
    const load = async () => {
      const params = new URLSearchParams(window.location.search)
      const hash = params.get('hash')
      if(!hash) { return setError('Not Find') }

      setHash(hash)
      
      const invoice = await get(hash)
      if(!invoice) { return setError('Not Find') }

      setIncoice(invoice)
    }

    load()
  }, [])

  const payHandler = async () => {
    if(!hash) { return }

    const url = await pay(hash, number.value, month.value, year.value, cvv.value)
    console.log(url);    
    if(!url) { return setError('Wrong data') }

    window.location.href = url;
    setMess('Redirect to Bank')
  }

  return (
    <div className="main">
      <div className="window">
        <div className="label" status={invoice?.status}>{invoice?.status}</div>
        <div className="title">Сумма до сплаты</div>
        <div className="value">
          <div className="amount">{invoice?.amount}</div>
          <div>UAH</div>
        </div>
        {invoice?.status === 'WAIT' && (<>
          <div className="form">
              <div>
                <input {...number.bind} className="number" placeholder='Card Number' />
              </div>
              <div>
                  <input {...month.bind} className="month" placeholder='MM' />/
                  <input {...year.bind} className="year" placeholder='YY' />
                  <input {...cvv.bind} className="cvv" placeholder='CVV' />
              </div>
          </div>
          <div className="btn" onClick={() => payHandler()}>Pay</div>
        </>)}
      </div>

      <div className="alert" open={!!alert? 1 : 0}>
        {alert && alert.error && <div className="icon"><img src='./warning.png' alt='ERROR' /></div>}
        {alert && !alert.error && <div className="icon"><img src='./check.png' alt='OK' /></div>}
        <div>{alert?.mess}</div>
      </div>
    </div>
  )
}

export default App
