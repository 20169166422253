import { useCallback } from 'react'

import useHttp from './http.hook'


export default function useApi() {
    const { request } = useHttp()
    
    const publicRequest = useCallback(async (queris, data, type) => {
        try { return await request(queris, 'POST', data, {}, type) } 
        catch(error) { throw error } 
    }, [request])

    return { 
        publicRequest
    }
}