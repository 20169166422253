import useApi from '../hooks/api.hook'


export default function usePayApi() {
    const { publicRequest } = useApi()

    const get = async (hash) => {
        try { return await publicRequest('api/pay/get', { hash }) }
        catch(error) { return null } 
    } 

    const pay = async (hash, number, month, year, cvv) => {
        try { return await publicRequest('api/pay/create-window', { hash, number, month, year, cvv }) }
        catch(error) { return null } 
    } 

    return { 
        get,
        pay
    }
}