import { useCallback } from 'react'


export default function useHttp() {
    const BASE_URL = 'https://acqpay.io'

    const request = useCallback(async (url, method='GET', body=null, headers={}, type='json') => {
        function newError(status=520, message = 'Opps..') { return { status, message } }

        try {
            if(body && type === 'json') { 
                body = JSON.stringify(body) 
                headers['Content-Type'] = 'application/json'
            }

            const response = await fetch(`${BASE_URL}/${url}`, {method, body, headers})
            const data = await response.json()

            if(!response.ok) { throw newError(response.status, data.error) }

            return data
        } catch(e) { throw e }
    }, [])


    return { request }
}